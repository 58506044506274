"use client"

import { useEffect } from "react"
import Link from "next/link"
import Image from "next/image"
import { Button } from "@/components/ui/button"
import { Home, RefreshCw } from "lucide-react"
import { BackgroundPattern } from "@/components/ui/background-pattern"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error("Application error:", error)
  }, [error])

  return (
    <BackgroundPattern className="min-h-screen bg-gray-50">
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="bg-red-900 p-4 text-white text-center">
            <h1 className="text-xl font-bold">Something went wrong!</h1>
          </div>

          <div className="p-6 flex flex-col items-center">
            <div className="w-20 h-20 rounded-full bg-gray-100 flex items-center justify-center mb-6">
              <Image src="/discord-logo.png" alt="Discord Logo" width={40} height={40} className="rounded-full" />
            </div>

            <h2 className="text-xl font-semibold mb-2 text-center">An unexpected error occurred</h2>
            <p className="text-gray-500 mb-6 text-center">
              We're sorry for the inconvenience. Please try again or return to the home page.
            </p>

            <div className="flex flex-col sm:flex-row gap-3">
              <Button variant="outline" onClick={reset} className="flex items-center gap-2">
                <RefreshCw className="h-4 w-4" />
                Try Again
              </Button>

              <Link href="/" passHref>
                <Button className="flex items-center gap-2">
                  <Home className="h-4 w-4" />
                  Back to Home
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BackgroundPattern>
  )
}

