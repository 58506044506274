import type React from "react";

interface BackgroundPatternProps {
  children: React.ReactNode;
  pattern?: "dots" | "grid" | "discord" | "none";
  className?: string;
}

export function BackgroundPattern({
  children,
  pattern = "dots",
  className = "",
}: BackgroundPatternProps) {
  const getPatternStyle = () => {
    switch (pattern) {
      case "dots":
        return {
          backgroundImage: `radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px)`,
          backgroundSize: "20px 20px",
        };
      case "grid":
        return {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px), 
                           linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px)`,
          backgroundSize: "20px 20px",
        };
      case "discord":
        return {
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.6667 5.00001C16.6667 5.00001 14.7833 3.50001 12.5 3.33334L12.3117 3.70001C14.3667 4.20001 15.3 4.90001 16.25 5.83334C14.7667 5.08334 13.2833 4.33334 10.8333 4.33334C8.38333 4.33334 6.9 5.08334 5.41667 5.83334C6.36667 4.90001 7.5 4.10001 9.35 3.70001L9.16667 3.33334C6.95 3.50001 5 5.00001 5 5.00001C5 5.00001 2.83333 8.10001 2.5 14.1667C4.5 16.5 7.5 16.6667 7.5 16.6667L8.25 15.6667C7.06667 15.2667 5.76667 14.5 4.83333 13.1667C5.83333 13.9 7.38333 14.6667 10 14.6667C12.6167 14.6667 14.1667 13.9 15.1667 13.1667C14.2333 14.5 12.9333 15.2667 11.75 15.6667L12.5 16.6667C12.5 16.6667 15.5 16.5 17.5 14.1667C17.1667 8.10001 15 5.00001 15 5.00001H16.6667ZM7.93333 12.5C7.05 12.5 6.33333 11.7 6.33333 10.7333C6.33333 9.76667 7.05 8.96667 7.93333 8.96667C8.81667 8.96667 9.53333 9.76667 9.53333 10.7333C9.53333 11.7 8.81667 12.5 7.93333 12.5ZM12.7333 12.5C11.85 12.5 11.1333 11.7 11.1333 10.7333C11.1333 9.76667 11.85 8.96667 12.7333 8.96667C13.6167 8.96667 14.3333 9.76667 14.3333 10.7333C14.3333 11.7 13.6167 12.5 12.7333 12.5Z' fill='rgba(88, 101, 242, 0.1)'/%3E%3C/svg%3E")`,
          backgroundSize: "40px 40px",
        };
      case "none":
      default:
        return {};
    }
  };

  return (
    <div className={`${className}`} style={getPatternStyle()}>
      {children}
    </div>
  );
}
